.fallbackWrp {
  .title {
    margin-bottom: 5px;
  }

  .tip {
    font-size: 14px;
  }

  .tab {
    padding-inline-start: 10px;
  }
}

.checkSvg {
  width: 15px;
  height: 15px;
}
