@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&family=Heebo:wght@300;400;500;900&display=swap");

:root {
  --primary-color: #2d34b2;
  --light-background: #dadefc;
  --primary-text: #2c2c2c;
  --dark-gray: #828282;
  --light-purple: #daddf9;
  --lighter-purple2: #5e65dc;
  --lighter-purple3: #78789c;
  --light-purple5: #daddf9;
  --lighter-purple: #5e65dc;
  --light-blue: #809dd6;
  --red: #d33b3b;
  --light-green: #67da5d;
  --gray: #333333;
  --gray2: #4f4f4f;
  --light-gray: #e6e6e7;
  --line: #3c4044;
}

html,
body {
  font-family: Rubik;
  text-align: left;
  direction: ltr;
  background-color: #202123;
  color: #bdc1c6;
  height: 100%;
}

#root {
  display: flex;
  justify-content: center;
  height: 100%;
}

a {
  color: var(--primary-color);
}

.adminButton {
  border-radius: 30px;
  background-color: light-purple !important;
}

.adminButton.transparent {
  border-radius: 30px;
  background-color: transparent !important;
  border: none;
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.rtl {
  direction: rtl;
  text-align: right;
}

.ltr {
  direction: ltr;
  text-align: left;
}

.visibility-hidden {
  visibility: hidden;
}

.mt4 {
  margin-top: 4px;
}
.mb4 {
  margin-bottom: 4px;
}
.ml4 {
  margin-left: 4px;
}
.mr4 {
  margin-right: 4px;
}

.mt8 {
  margin-top: 8px;
}
.mb8 {
  margin-bottom: 8px;
}
.ml8 {
  margin-left: 8px;
}
.mr8 {
  margin-right: 8px;
}

.mt12 {
  margin-top: 12px;
}
.mb12 {
  margin-bottom: 12px;
}
.ml12 {
  margin-left: 12px;
}
.mr12 {
  margin-right: 12px;
}

.mt16 {
  margin-top: 16px;
}
.mb16 {
  margin-bottom: 16px;
}
.ml16 {
  margin-left: 16px;
}
.mr16 {
  margin-right: 16px;
}

.mt20 {
  margin-top: 20px;
}
.mb20 {
  margin-bottom: 20px;
}
.ml20 {
  margin-left: 20px;
}
.mr20 {
  margin-right: 20px;
}

.mt24 {
  margin-top: 24px;
}
.mb24 {
  margin-bottom: 24px;
}
.ml24 {
  margin-left: 24px;
}
.mr24 {
  margin-right: 24px;
}

.mt28 {
  margin-top: 28px;
}
.mb28 {
  margin-bottom: 28px;
}
.ml28 {
  margin-left: 28px;
}
.mr28 {
  margin-right: 28px;
}

.mt32 {
  margin-top: 32px;
}
.mb32 {
  margin-bottom: 32px;
}
.ml32 {
  margin-left: 32px;
}
.mr32 {
  margin-right: 32px;
}

.mt36 {
  margin-top: 36px;
}
.mb36 {
  margin-bottom: 36px;
}
.ml36 {
  margin-left: 36px;
}
.mr36 {
  margin-right: 36px;
}

.flex-1 {
  flex: 1;
}

.modal-content {
  box-shadow: 0px 100px 208px rgba(20, 20, 20, 0.07),
    0px 12.5216px 21.2384px rgba(20, 20, 20, 0.035);
  border-radius: 12px;
  border: none;
  color: var(--primary-text);
}

.modal-header {
  border-bottom: none;
}

.modal-header .modal-title {
  text-align: center;
  width: 100%;
  font-size: 24px;
  line-height: 30px;
  font-weight: normal;
  color: var(--primary-text);
  margin-top: 30px;
}

.modal-footer {
  display: flex;
  flex-direction: column;
  border-top: none;
  padding-bottom: 30px;
}

.modal-header .close {
  font-weight: 100;
  position: absolute;
  top: 20px;
  right: 20px;
  border: 0;
  background: white;
}

.modal-footer button {
  width: 235px;
}

@media only screen and (max-width: 1000px) {
  .modal-footer button {
    width: 150px;
  }
}

.modal-body {
  padding: 25px 30px;
}

@media only screen and (max-width: 1296px) {
  .modal-body {
    padding: 10px;
  }
}

.grow {
  transition: all 0.2s ease-in-out;
}

.grow:hover {
  transform: scale(1.1);
}

.ReactVirtualized__Grid {
  outline: none;
}

[class*="indicatorSeparator"] {
  display: none;
}

.react-tagsinput {
  border: 1px solid var(--light-purple);
  border-radius: 6px;
  min-height: 54px;
}

.react-tagsinput--focused {
  border: 1px solid var(--light-purple) !important;
}

.react-tagsinput .react-tagsinput-tag {
  background: #f5f6ff;
  border-radius: 12px;
  font-size: 16px;
  line-height: 140%;
  color: var(--primary-color);
  border: 0;
  padding: 10px 15px;
}

.react-tagsinput .react-tagsinput-remove {
  color: var(--primary-color);
}

.react-tagsinput .react-tagsinput-input {
  width: 120px;
}

.capitalize {
  text-transform: capitalize;
}

.pointer {
  cursor: pointer;
}

.error {
  font-size: 14px;
  line-height: 120%;
  color: var(--red);
}

.success {
  font-size: 14px;
  line-height: 120%;
  color: var(--light-green);
}

.dropdown-menu {
  font-size: 14px;
  line-height: 120%;
  color: var(--primary-text);
  border: 0;
  border-radius: 6px;
  top: 15px !important;
  box-shadow: 0px 100px 208px rgba(20, 20, 20, 0.07),
    0px 12.5216px 21.2384px rgba(20, 20, 20, 0.035);
}

.dropdown-menu button {
  text-align: right;
  height: 41px;
}

.dropdown-item {
  outline: none !important;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: var(--light-background);
}

.react-datepicker-wrapper.text input {
  background: transparent;
  border: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 120%;
  color: var(--primary-color);
}

.react-datepicker-wrapper.select {
  flex: 1;
}

.react-datepicker-wrapper.select input {
  height: 62px;
  border: 1px solid var(--light-purple);
  border-radius: 6px;
  padding: 0 10px;
  color: var(--primary-text);
  width: 100%;
}

.source-box {
  background: #f8f8f8;
  padding: 10px;
  text-align: left;
}

.source-box span {
  color: var(--primary-text);
}

.source-box span.key {
  color: var(--primary-color);
}

.spinner-border {
  color: var(--primary-color);
}

.tooltip .tooltip-inner {
  background: white;
  font-size: 14px;
  line-height: 120%;
  color: var(--primary-text);
  border-radius: 4px;
  box-shadow: 0px 100px 208px rgba(20, 20, 20, 0.07),
    0px 12.5216px 21.2384px rgba(20, 20, 20, 0.035);
  padding: 12px 10px;
}

.tooltip .arrow::before {
  border-bottom-color: white !important;
}

.spinner-wrapper {
  display: flex;
  justify-content: center;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.li-boot-order {
  list-style: none;
  cursor: pointer;
  font-size: 18px;
  color: var(--primary-text);
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--primary-color);
  border-radius: 6px;
  width: 150px;
  text-align: center;
}

::-webkit-scrollbar {
  width: 3px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--light-purple);
}

::-webkit-scrollbar-thumb {
  background: var(--lighter-purple);
}

.rc-time-picker-input {
  height: 62px !important;
  font-size: 16px !important;
}

.link-disabled {
  pointer-events: none;
  opacity: 0.5;
}

.locale-he .dropdown-toggle::after {
  margin-left: initial;
  margin-right: 0.255em;
}

.flex1 {
  flex: 1;
}

button[disabled] {
  opacity: 0.5;
}

.modal-dialog {
  max-width: 500px;
  margin: 11.75rem auto !important;
}
.LoginMainButton {
  text-align: center;
}

.login-terra .login-inner {
  position: relative;
}

.login-terra .login-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  padding: 25px;
  background: white;
  box-shadow: 0 0 20px;
}

.login-inner-img img {
  height: 100%;
  width: 260px;
  object-fit: cover;
  margin-bottom: 60px;
}

.login-inner-form input {
  display: inline-block;
  width: 450px;
  border-bottom: 2px solid #000000 !important;
  border: none;
  background: transparent;
  padding-bottom: 15px;
  padding-top: 5px;
  padding-left: 46px;
}

.login-inner-form input::placeholder {
  color: #cccccc;
}

.login-inner-form {
  position: relative;
}

.input-email {
  margin-bottom: 70px;
}

.input-password {
  margin-top: 70px;
}

.input-password img,
.input-email img {
  height: 25px;
  width: 25px;
  object-fit: contain;
  float: left;
  margin-bottom: -20px;
  display: inline-block;
  margin-right: -30px;
  position: relative;
}

.input-password {
  display: flex;
}

.input-eye img {
  height: 100%;
  width: 25px;
  object-fit: contain;
  float: right;
  display: inline-block;
  position: relative;
  bottom: 3px;
  right: 46px;
}

.login-inner-btn {
  background: #000000;
  color: #fff;
  text-decoration: none;
  padding: 16px 46px;
  border-radius: 16px;
  display: inline-block;
  font-size: 18px;
  margin-top: 55px;
}

.login-inner-form input:focus-visible {
  outline: none !important;
}

.emailicon {
  position: relative;
  left: 24px;
  fill: #000000 !important;
}
@media screen and (max-width: 567px) {
  .login-inner-form input {
    width: 280px;
  }
}
.main-loader {
  .MuiBox-root {
    position: absolute;
    right: 0px;
    left: 0px;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    justify-content: center;
    align-items: center;
  }
}

.opactiy-0 {
  opacity: 0 !important;
}
.opactiy-100 {
  opacity: 100 !important;
}
