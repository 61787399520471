.tip {
  font-size: 14px;
  margin-bottom: 20px;
}

.textRow {
  display: flex;
  gap: 10px;
  align-items: center;
}

.addWrp {
  display: flex;
  justify-content: flex-end;

  svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
}
