.row {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  
    span {
      flex: 0 0 110px;
    }
  }
  
  .list {
    height: 150px;
    overflow-y: scroll;
  }
  
  .filter {
    position: sticky;
    top: 0;
    background: white;
    margin-bottom: 5px;
    z-index: 1;
  
    input {
      padding: 5px 10px;
      height: 30px;
    }
  }
  
  .image {
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
  
  .contactWrapper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    cursor: pointer;
  
    .image {
      margin-inline-end: 5px;
    }
  }
  
  .picture {
    position: relative;
  
    .check {
      position: absolute;
      fill: #5e65dc;
      bottom: -4px;
      right: -7px;
    }
  }
  
  .autoReloadMessage {
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 5px;
  
    svg {
      width: 15px;
      height: 15px;
      margin-inline-start: 5px;
    }
  }
  