.actions {
  svg {
    margin-right: 5px;
    cursor: pointer;
  }
}

.ColumnHead {
  margin-bottom : 20px ;
   font-weight : bold ;
}
